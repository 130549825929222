<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <v-btn @click="emitClick" color="primary" fab small elevation="0" class="typeD-btn white--text">
    <!-- <v-icon class="text-2">ri-loader-line</v-icon> -->
    <!-- <v-icon class="text-2">ri-apps-fill</v-icon> -->
    <v-icon class="text-2">ri-add-line</v-icon>
    <!-- <v-icon class="text-2">ri-checkbox-blank-circle-fill</v-icon> -->
    <!-- <v-icon class="text-2">ri-arrow-left-up-line</v-icon> -->
    
    
    
  </v-btn>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  methods: {
    emitClick() {
      this.$emit('click')
    },
  },
};
</script>