<template>
    <!-- 第2層 -->
    <div 
        class="ecvNavbar__submenu__item"
        @mouseenter="isElementInViewport('.ecvNavbar__submenu__item__menu', index)"
    >
        <div class="ecvNavSubMenuTitle">
            <link-node tooltip :node="data"></link-node>
        </div>
        
        <!-- 第3層 -->
        <sub-menu-third
            v-if="thirdItems.length > 0"
            :items="thirdItems"
            :index="index"
            :isInView="isInView"
        ></sub-menu-third>
    </div>
</template>

<script>
import subMenuThird from './subMenuThird.vue'
export default {
  components: { subMenuThird },
    props: {
        data: {
            type: Object,
            default: () => null,
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        isInView: true,
    }),
    computed: {
        thirdItems() {
            return this.data.children ?? []
        },
    },
    methods: {
        async isElementInViewport(className, index) {
            await this.$nextTick()
            const dom = this.$el.querySelector('.ecvNavbar__submenu__item__menu')
            if(!dom) return
           const isInViewport = this.$helper.isElementInViewport(dom)
           if(isInViewport) return
           this.isInView = false
        }
    },
}
</script>