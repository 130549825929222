/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import env from 'config/env.json'
import {siteCodeFallback} from 'config/site.js'
export default {
  namespaced: true,
  state: {
    application: null,
    applicationReady: false,
    vuetify: null,
    loading: [],
    navHeight: 0,
    subNavHeight: 0,
    breadcrumbHeight: 0,
    nav: {
      desktopMenu: false,
      mobileMenu: false,
      ecvNavLocation: false,
      ecvNavSearch: false,
    },
    desktopMenuOpenIndex: -1,
    anchor: [],
    showAnchor: true,
    breadcrumb: [],
    mainApiLoadDone: false,
    logoChangeBase: 0, // 如果頁面沒設定此參數, 則logo都不會變色
    pageData: null,
    // 0: 隱藏, 1: 顯示, 2: 顯示但沒作用
    componentStatus: {
      header_menu: 1,
      logo: 1,
      search: 1,
      location: 1,
      breadcrumb: 1,
      footer: 1
    },
    scroll: 0,
  },
  mutations: {
    set(state, data) {
      state[data.key] = data.value
    },
    setApplication(state, data) {
      state.application = data
    },
    setVuetify(state, data) {
      state.vuetify = data
    },
    setNavHeight(state, data) {
      state.navHeight = data
    },
    setSubNavHeight(state, data) {
      state.subNavHeight = data
    },
    setBreadcrumbHeight(state, data) {
      state.breadcrumbHeight = data
    },
    setNav(state, { key, status }) {
      if (status == true) {
        for (let key in state.nav) {
          state.nav[key] = false
        }
        state.nav[key] = true
        return
      }
      state.nav[key] = false
    },
    closeNav(state) {
      for(let key in state.nav) {
        state.nav[key] = false
      }
    },
    setAnchor(state, data) {
      state.anchor = data
    },
    setShowAnchor(state, data) {
      state.showAnchor = data
    },
    loading(state, data = true) {
      if(data) {
        state.loading.push(1)
        return
      }
      state.loading.shift()
    },
    setBreadcrumb(state, data = null) {
      state.breadcrumb = data
    },
    setMainApiLoadDone(state, data) {
      state.mainApiLoadDone = data
    },
    setLogoChangeBase(state, data) {
      state.logoChangeBase = data
    },
    setPageData(state, data) {
      state.pageData = data
    },
    setComponentStatus(state, {key, value}) {
      state.componentStatus[key] = value
    },
    resetComponentStatus(state) {
      state.componentStatus = {
        header_menu: 1,
        logo: 1,
        search: 1,
        location: 1,
        breadcrumb: 1,
        footer: 1
      }
    },
    setScroll(state, data) {
      state.scroll = data
    },
    setDesktopMenuOpenIndex(state, data) {
      state.desktopMenuOpenIndex = data
    },
  },
  actions: {
    set(context, data) {
      context.commit('set', data)
    },
    setApplication(context, data) {
      context.commit('setApplication', data)
    },
    setNavHeight(context, data) {
      context.commit('setNavHeight', data)
    },
    setSubNavHeight(context, data) {
      context.commit('setSubNavHeight', data)
    },
    loading(context, data) {
      context.commit('loading', data)
    },
    async setBreadcrumbHeight({commit}) {
      await this._vm.$nextTick();
      const target = $("#ecvNavbar__breadcrumb")[0];
      if (!target) {
        commit("setBreadcrumbHeight", 0);
        return;
      }
      commit("setBreadcrumbHeight", target.offsetHeight);
    },
  },
  getters: {
    loading: state => {
      if(state.loading.length == 0) return false
      return true
    },
    application: state => state.application,
    applicationReady: state => state.applicationReady,
    applicationLoaded: state => !!state.application,
    site: state => {
      if (!state.application) return null
      return state.application.site
    },
    siteData: (state, getters) => {
      if (!getters.application) return null
      return window._get(getters.application, ['site_data'])
    },
    siteTextUrl: (state, getters) => {
      if (!getters.application) return null
      return window._get(getters.application, ['site_data', 'text_url'])
    },
    navHeight: state => state.navHeight,
    subNavHeight: state => state.subNavHeight,
    breadcrumbHeight: state => state.breadcrumbHeight,
    navTotalHeight: state => state.navHeight + state.breadcrumbHeight,
    nav: state => state.nav,
    desktopMenu: state => state.nav.desktopMenu,
    mobileMenu: state => state.nav.mobileMenu,
    ecvNavLocation: state => state.nav.ecvNavLocation,
    ecvNavSearch: state => state.nav.ecvNavSearch,
    navWhoOpen: state => {
      for (let key in state.nav) {
        if (state.nav[key]) return key;
      }
      return null
    },
    desktopMenuOpenIndex: state => state.desktopMenuOpenIndex,
    windowWidth: state => {
      if(!state.vuetify) return 0
      return state.vuetify.breakpoint.width
    },
    hideBodyScroll: function(state, getters) {
      if(!state.vuetify) return false
      if(getters.mobileMenu) return true
      if(getters.ecvNavLocation && getters.windowWidth <= state.vuetify.breakpoint.thresholds.xs) return true
      return false
    },
    anchor: state => state.anchor,
    showAnchor: state => state.showAnchor,
    siteId: (state, getters) => {
      if(!getters.siteData) return ''
      return getters.siteData.id
    },
    siteName: (state, getters) => {
      return 'eCloudvalley Digital Technology'
    },
    siteLanguage: (state, getters) => {
      if(!getters.siteData) return 'zh-TW'
      return getters.siteData.language
    },
    breadcrumb: state => {
      return state.breadcrumb
    },
    cookie: (state, getters) => {
      if (!getters.siteData) return null;
      return getters.siteData.cookie;
    },
    seoTitle: (state, getters) => {
      if (!getters.siteData) return "";
      return getters.siteData.seo_title;
    },
    seoDescription: (state, getters) => {
      if (!getters.siteData) return "";
      return getters.siteData.seo_description;
    },
    seoKeywords: (state, getters) => {
      if (!getters.siteData) return "";
      return getters.siteData.seo_keywords;
    },
    seoPhoto: (state, getters) => {
      if (!getters.siteData) return "";
      return getters.siteData.seo_photo;
    },
    seoObj: (state, getters) => {
      if (!getters.siteData) return null;
      return getters.siteData.seo;
    },
    blog_seo_title: (state, getters) => {
      if (!getters.seoObj) return getters.seoTitle;
      return getters.seoObj.blog_seo_title;
    },
    blog_seo_description: (state, getters) => {
      if (!getters.seoObj) return getters.seoDescription;
      return getters.seoObj.blog_seo_description || getters.seoDescription;
    },
    blog_seo_keywords: (state, getters) => {
      if (!getters.seoObj) return getters.seoKeywords;
      return getters.seoObj.blog_seo_keywords || getters.seoKeywords;
    },
    media_seo_title: (state, getters) => {
      if (!getters.seoObj) return getters.seoTitle;
      return getters.seoObj.media_seo_title;
    },
    media_seo_description: (state, getters) => {
      if (!getters.seoObj) return getters.seoDescription;
      return getters.seoObj.media_seo_description || getters.seoDescription;
    },
    media_seo_keywords: (state, getters) => {
      if (!getters.seoObj) return getters.seoKeywords;
      return getters.seoObj.media_seo_keywords || getters.seoKeywords;
    },
    cloud_seo_title: (state, getters) => {
      if (!getters.seoObj) return getters.seoTitle;
      return getters.seoObj.cloud_seo_title || getters.seoTitle;
    },
    cloud_seo_description: (state, getters) => {
      if (!getters.seoObj) return getters.seoDescription;
      return getters.seoObj.cloud_seo_description || getters.seoDescription;
    },
    cloud_seo_keywords: (state, getters) => {
      if (!getters.seoObj) return getters.seoKeywords;
      return getters.seoObj.cloud_seo_keywords || getters.seoKeywords;
    },
    paper_seo_title: (state, getters) => {
      if (!getters.seoObj) return getters.seoTitle;
      return getters.seoObj.paper_seo_title || getters.seoTitle;
    },
    paper_seo_description: (state, getters) => {
      if (!getters.seoObj) return getters.seoDescription;
      return getters.seoObj.paper_seo_description || getters.seoDescription;
    },
    paper_seo_keywords: (state, getters) => {
      if (!getters.seoObj) return getters.seoKeywords;
      return getters.seoObj.paper_seo_keywords || getters.seoKeywords;
    },
    mainApiLoadDone: (state) => state.mainApiLoadDone,
    logoChangeBase: (state) => state.logoChangeBase,
    pageData: (state) => state.pageData,
    publicDomain: (state) => {
      if(!state.application) return ''
      return state.application.publicDomain
    },
    componentStatus: state => state.componentStatus,
    scroll: state => state.scroll,
  },
}
