<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <footer id="ecvFooter" class="ecvFooter" v-if="footerMeta">
    <ecvWaveFooter :class="footerWaveClass" />

    <div
      v-if="!showFooter"
      class="ecvFooterWrapper ecvFooterWrapper--preview-hidden"
    ></div>

    <template v-else>
      <!-- mobile -->
      <div class="ecvFooterWrapper ecvFooterWrapper--mobile">
        <section class="ecvFooterContainer">
          <div class="ecvFooterCompany">
            <!-- ---Logo + Branding Quote--- -->
            <div class="ecvLogoBox d-flex justify-center align-center">
              <v-img
                contain
                width="180"
                height="50"
                :src="require('@/assets/img/logo/ECV_logo_WHT.svg')"
              ></v-img>
            </div>
            <brief :footer-meta="footerMeta"></brief>
            <!-- ---x6 Menu--- -->
            <cardLink :footer-meta="footerMeta" fluid></cardLink>

            <!-- ---EDM--- -->
            <subscription :footerMeta="footerMeta"></subscription>
          </div>
          <ecvFooterSocial
            class="justify-center align-center"
            :footerMeta="footerMeta"
          ></ecvFooterSocial>
          <div class="px-md-4 px-8 py-10">
            <div
              class="ecvFooterMenu"
              v-for="item in items"
              :key="`mobile-${item.id}`"
            >
              <h3>
                <link-node tooltip :node="item"></link-node>
              </h3>
              <div v-if="item.children.length > 0">
                <v-expansion-panels class="ecv-panel" accordion>
                  <v-expansion-panel
                    v-for="secItem in item.children"
                    :key="`mobile-${secItem.id}`"
                  >
                    <v-expansion-panel-header
                      :class="{ 'no-child': secItem.children.length == 0 }"
                    >
                      <link-node
                        tooltip
                        class="ecvFooterMenuSec ecv-panel-header-text"
                        :node="secItem"
                      ></link-node>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                      v-if="secItem.children.length > 0"
                    >
                      <div
                        v-for="(thirdItem, index) in secItem.children"
                        :key="index"
                        class="ecvFooterMenuThirdWrap ecv-panel-content-wrap"
                      >
                        <link-node
                          tooltip
                          class="ecvFooterMenuThird ecv-panel-content-text"
                          :node="thirdItem"
                        ></link-node>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
          </div>

          <div class="divide mx-4"></div>
          <div class="ecvFooterCopyright px-md-4 px-8 py-10">
            <p class="mb-5 white--text text-center">
              {{ copyright }}
            </p>

            <info :footer-meta="footerMeta"></info>
          </div>
        </section>
      </div>

      <!-- pc -->
      <div class="ecvFooterWrapper">
        <v-container fluid class="ecvFooterContainer inner-container">
          <v-row>
            <v-col cols="12" md="auto" class="pb-0" style="flex: 1">
              <div class="ecvFooterMenu" v-for="item in items" :key="item.id">
                <h3>
                  <link-node tooltip :node="item"></link-node>
                </h3>
                <div class="d-flex flex-wrap">
                  <div
                    class="ecvFooterMenuBox"
                    v-for="secItem in item.children"
                    :key="secItem.id"
                  >
                    <h3 class="ecvFooterMenuSec">
                      <link-node tooltip :node="secItem"></link-node>
                    </h3>
                    <ul
                      class="ecvFooterMenuThird"
                      v-if="secItem.children.length > 0"
                    >
                      <li
                        class="ecvFooterMenuItem"
                        v-for="thirdItem in secItem.children"
                        :key="thirdItem.id"
                      >
                        <link-node tooltip :node="thirdItem"></link-node>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" md="auto" class="pb-0">
              <div style="width: 364px" class="ecvFooterCompany">
                <!-- ---Logo + Branding Quote--- -->
                <div class="ecvLogoBox d-flex justify-start align-center">
                  <v-img
                    contain
                    max-width="120"
                    height="50"
                    :src="require('@/assets/img/logo/ECV_logo_WHT.svg')"
                  ></v-img>
                </div>
                <brief :footer-meta="footerMeta"></brief>

                <!-- ---x6 Menu--- -->
                <cardLink :footer-meta="footerMeta"></cardLink>

                <!-- ---EDM--- -->
                <subscription :footerMeta="footerMeta"></subscription>
              </div>
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="12" md="auto" class="pt-0 pb-0" style="flex: 1">
              <div
                class="
                  ecvFooterCopyright
                  d-flex
                  justify-space-between
                  align-center
                  flex-wrap
                "
              >
                <div>
                  <p class="mb-0 white--text mb-2">
                    {{ copyright }}
                  </p>
                </div>
                <info class="d-flex mb-2" :footer-meta="footerMeta"></info>
              </div>
            </v-col>
            <v-col cols="12" md="auto" class="pt-0 pb-0">
              <ecvFooterSocial
                style="width: 364px"
                class="align-center"
                :footerMeta="footerMeta"
              ></ecvFooterSocial>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
  </footer>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import ecvWaveFooter from "@/components/wave/ecvWaveFooter.vue";
export default {
  components: {
    ecvWaveFooter,
    brief: () => import("./brief.vue"),
    ecvFooterSocial: () => import("./ecvFooterSocial.vue"),
    subscription: () => import("./subscription.vue"),
    info: () => import("./info.vue"),
    cardLink: () => import("./cardLink.vue"),
  },
  async mounted() {
    await this.init();
    this.setObserver();
  },
  data: () => ({
    items: [],
    footerWaveClass: "",
    footerMeta: null,
    observerThreshold: 0.01,
  }),
  computed: {
    copyright() {
      const year = this.$helper.moment().format('YYYY')
      return `Copyright © ${year} eCloudvalley Digital Technology Co. Ltd. All Rights Reserved.`
    },
    showAnchor() {
      return this.$store.getters["base/showAnchor"];
    },
    showFooter() {
      return this.$store.getters["base/componentStatus"].footer != 0;
    },
    siteId() {
      return this.$store.getters["base/siteId"];
    },
    footerId() {
      if (this.$route.name != "footer-preview") return null;
      return this.$route.params.id;
    },
    code() {
      if (this.$route.name != "footer-preview") return null;
      return this.$route.query.viewCode;
    },
    isPreviewMode() {
      if (!this.footerId) return false;
      if (!this.code) return false;
      return true;
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        if (this.$route.name === "404-page") {
          this.footerWaveClass = "footer404";
          return;
        }
        this.footerWaveClass = "";
      },
    },
  },
  methods: {
    async setObserver() {
      await this.$helper.delay(0.5)
      await this.$nextTick();
      const anchorPoint = $(".ecv-anchor-point");
      if (anchorPoint.length == 0) return;

      const padding = anchorPoint.innerHeight() - anchorPoint.height();
      const options = {
        root: null,
        rootMargin: `-${padding}px`, // 參照anchorPoint的padding-bottom
        threshold: this.observerThreshold,
      };
      const observer = new IntersectionObserver(this.obCallback, options);

      const target = document.querySelector("#ecvFooter");
      if (!target) return;
      observer.observe(target);
    },
    obCallback(entries) {
      entries.forEach((entry) => {
        if (entry.intersectionRatio >= this.observerThreshold) {
          this.$store.commit("base/setShowAnchor", false);
          return;
        }
        this.$store.commit("base/setShowAnchor", true);
      });
    },
    async callFooter() {
      if (this.isPreviewMode) {
        return await this.$api.footer.public.preview(this.footerId, this.code);
      }
      return await this.$api.footer.public.read(this.siteId);
    },
    async init() {
      try {
        const result = await this.callFooter();
        this.footerMeta = result.meta;
        this.items = _get(result, "data.children");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
