<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div
    ref="main-top"
    class="main-top mb-10 mb-md-15"
    :style="{ 'padding-top': `${navTotalHeight}px` }"
  >
    <v-container class="inner-container">
      <h1 class="main-top_title mb-md-5 mb-2 text-overflow-ep-3">{{ title }}</h1>
      <div v-if="filterMode" class="main-top_filterBox">
        <div class="d-flex justify-content-start align-center mb-6">
          <v-img
            contain
            max-width="24"
            max-height="24"
            class="me-5"
            src="@/assets/img/icon/tags.svg"
          ></v-img>
          <div class="main-top_filterBox_tagBox">
            <v-chip
              v-for="category in categories"
              :key="category.id"
              class="main-top_filterBox_tagBox_tag my-1"
              :class="{ active: findIndex(selected, category.id) > -1 }"
              @click="toggle(category)"
              >{{ category.name }}</v-chip
            >
          </div>
        </div>
        <div class="d-flex justify-content-start align-center mt-3 mb-5">
          <v-img
            contain
            max-width="24"
            max-height="24"
            class="me-5"
            src="@/assets/img/icon/calendar.svg"
          ></v-img>
          <div class="main-top_filterBox_dateBox">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              elevation="0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDate"
                  v-bind="attrs"
                  v-on="on"
                  :label="$t('select.month')"
                  @click:clear="clearMonth"
                  rounded
                  hide-details
                  solo
                  clearable
                  style="width: 220px"
                  class="dateBox_text"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                type="month"
                color="primary"
                no-title
                scrollable
                rounded
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  class="rounded-pill"
                  @click="menu = false"
                >
                  {{ 'action.close' | t }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  class="rounded-pill"
                  @click="confirmDate(date)"
                >
                  {{ 'action.apply' | t }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
      <div v-if="contentDate" class="my-5 pointer-none ml-1">
        <Main-btn outlined color="white">{{ modifyDate }}</Main-btn>
      </div>
      <slot name="contentSlot"></slot>
    </v-container>
    <ecvWaveR class="main-top_wave" />
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import ecvWaveR from "/src/components/wave/ecvWaveR.vue";
import qs from "qs";
export default {
  props: {
    title: String,
    filterMode: {
      type: Boolean,
      default: false,
    },
    contentDate: {
      type: String,
      default: "",
    },
    categoryData: Array,
    routeTime: String,
    routeCategory: Array,
  },
  components: {
    ecvWaveR,
  },
  data: () => ({
    date: "",
    menu: false,
    selected: [],
  }),
  computed: {
    modifyDate() {
      return this.$helper.formatDate(this, this.contentDate)
    },
    lang() {
      return this.$store.getters['base/siteLanguage']
    },
    computedDate: {
      get() {
        const reg = /(\d*)-(\d*)/;
        const newDate = this.date;
        if(!newDate) return ''
        let format
        if(['zh-TW', 'zh-CN'].includes(this.lang)) {
          format = 'YYYY/MM'
        } else {
          format = 'MMMM, YYYY'
        }
        return this.$helper.moment(this.date).locale(this.lang).format(format)
      },
      set() {},
    },
    navTotalHeight() {
      return this.$store.getters["base/navTotalHeight"];
    },
    categories() {
      if (_isEmpty(this.categoryData)) return [];
      return this.categoryData.sort((a, b) => a.sort - b.sort);
    },
    params() {
      let params = {};

      if (this.date) {
        const startDate = this.$helper
          .moment(`${this.date}-01`)
          .format("YYYY-MM-DD");
        const endDate = this.$helper
          .moment(startDate)
          .endOf("month")
          .format("YYYY-MM-DD");
        const date = `${startDate}.${endDate}`;
        params.time = date;
      }

      if (!_isEmpty(this.selected))
        params.category = this.selected.map((category) => category.id);

      return params;
    },
    mainApiLoadDone() {
      return this.$store.getters['base/mainApiLoadDone']
    },
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.selected = [];
      },
    },
    routeCategory: {
      immediate: true,
      deep: true,
      async handler() {
        await this.$nextTick();
        if (_isEmpty(this.routeCategory)) return;
        if (_isEmpty(this.categories)) return;
        this.selected = this.routeCategory.map((id) => {
          return this.categories.find((category) => category.id === id);
        });
      },
    },
    routeTime: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        if (!this.routeTime) {
          this.date = ''
          return
        };
        const dates = this.routeTime.split(".");
        if (_isEmpty(dates)) return;
        this.date = this.$helper.moment(dates[0]).format("YYYY-MM");
      },
    },
    async windowWidth() {
      await this.setLogoChangeBase();
    },
    async mainApiLoadDone() {
      if(!this.mainApiLoadDone) return
      await this.setLogoChangeBase()
    },
  },
  methods: {
    async setLogoChangeBase() {
      await this.$helper.delay(0.5)
      const mainTopHeight = this.$el.offsetHeight
      this.$store.commit("base/setLogoChangeBase", mainTopHeight);
      this.$root.$emit('onNavStatus')
    },
    async toggle(category) {
      const { id } = category;
      const index = this.findIndex(this.selected, id);
      if (index > -1) {
        this.selected.splice(index, 1);
        await this.$nextTick();
        this.changeRoute();
        return;
      }
      this.selected.push(category);

      await this.$nextTick();
      this.changeRoute();
    },
    findIndex(array, id) {
      return array.findIndex((item) => item.id === id);
    },
    async confirmDate(date) {
      this.$refs.menu.save(date);
      await this.$nextTick();
      this.changeRoute();
    },
    changeRoute() {
      if (_isEmpty(this.params)) {
        this.$router.push({
          name: this.$route.name,
          query: {},
        });
        return;
      }
      this.$router.push({
        name: this.$route.name,
        query: { q: qs.stringify(this.params, { encode: false }) },
      });
    },
    clearMonth() {
      this.date = "";
      this.changeRoute();
    },
  },
};
</script>