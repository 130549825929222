/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import storage from 'libs/storage/localStorage.js'
const STORAGE_LOCALE_KEY = 'anchor:site-locale'
class language {
  constructor() {
    this.storage = storage
  }
  getQueryLanguage() {
    let lang = null
    try {
      lang = (new URLSearchParams(window.location.search)).get('lang')
    } catch (error) {
      return null
    }
    return lang
  }

  init() {
    const queryLanguage = this.getQueryLanguage()
    if(queryLanguage) {
      return this.setLanguageToStorage(queryLanguage)
    }
  }

  setLanguageToStorage(language) {
    if(!language) return
    this.storage.set(STORAGE_LOCALE_KEY, language)
  }

  get() {
    const browserLanguage = navigator.language
    const storageLanguage = this.storage.get(STORAGE_LOCALE_KEY)
    if(storageLanguage) return storageLanguage
    return browserLanguage
  }
}

export default new language()