import { render, staticRenderFns } from "./linkNode.vue?vue&type=template&id=85ef1536&scoped=true"
import script from "./linkNode.vue?vue&type=script&lang=babel"
export * from "./linkNode.vue?vue&type=script&lang=babel"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85ef1536",
  null
  
)

export default component.exports