import { render, staticRenderFns } from "./popupItem.vue?vue&type=template&id=3dff7208&scoped=true"
import script from "./popupItem.vue?vue&type=script&lang=babel"
export * from "./popupItem.vue?vue&type=script&lang=babel"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dff7208",
  null
  
)

export default component.exports