/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default (api) => {
  api.base = {
    getTextJson: (language, filename) =>
      api.request({
        url: `/api/application/text`,
        type: "get",
        params: {
          language,
          filename,
        },
      }),
    remoteJson: (url) =>
      api.request({
        url,
        type: "get",
      }),
  };

  api.application = {
    read: (siteCode) =>
      api.request({
        url: `/api/application`,
        type: "get",
        params: {
          code: siteCode,
        },
      }),
  };

  api.page = {
    admin: {
      preview: (id, view_code) =>
        api.request({
          url: `/api/admin/draft_page/${id}/preview`,
          type: "get",
          params: {
            view_code,
          },
        }),
    },
    public: {
      index: (params) =>
        api.request({
          url: `/api/page/index`,
          type: "get",
          params,
        }),
      read: (site_id, code, secret_code) =>
        api.request({
          url: `/api/page/${site_id}/${code}`,
          type: "get",
          params: {
            ...(secret_code ? { secret_code } : null),
          },
        }),
      submit: (form_id, params) =>
        api.request({
          url: `/api/form/answer/${form_id}`,
          type: "post",
          params,
        }),
    },
  };

  api.file = {
    public: {
      download: (params) =>
        api.request({
          url: `/api/file/download`,
          type: "post",
          responseType: "blob",
          params,
        }),
    },
  };

  api.blog = {
    admin: {
      preview: (id, view_code) =>
        api.request({
          url: `/api/admin/draft_blog/${id}/preview`,
          type: "get",
          params: {
            view_code,
          },
        }),
    },
    public: {
      index: (params) =>
        api.request({
          url: `/api/blog/index`,
          type: "get",
          params,
        }),
      read: (site_id, code, secret_code) =>
        api.request({
          url: `/api/blog/${site_id}/${code}`,
          type: "get",
          params: {
            ...(secret_code ? { secret_code } : null),
          },
        }),
      categoryIndex: (params) =>
        api.request({
          url: `/api/blog/category/index`,
          type: "get",
          params,
        }),
      count: (params) =>
        api.request({
          url: `/api/blog/count`,
          type: "get",
          params,
        }),
      recommend: (site_id, code, params) =>
        api.request({
          url: `/api/blog/${site_id}/${code}/recommend`,
          type: "get",
          params,
        }),
        
    },
  };

  //白皮書
  api.paper = {
    admin: {
      preview: (id, view_code) =>
        api.request({
          url: `/api/admin/draft_paper/${id}/preview`,
          type: "get",
          params: {
            view_code,
          },
        }),
    },
    public: {
      index: (params) =>
        api.request({
          url: `/api/paper/index`,
          type: "get",
          params,
        }),
      read: (site_id, code, secret_code) =>
        api.request({
          url: `/api/paper/${site_id}/${code}`,
          type: "get",
          params: {
            ...(secret_code ? { secret_code } : null),
          },
        }),
      categoryIndex: (params) =>
        api.request({
          url: `/api/paper/category/index`,
          type: "get",
          params,
        }),
      count: (params) =>
        api.request({
          url: `/api/paper/count`,
          type: "get",
          params,
        }),
      recommend: (site_id, code, params) =>
        api.request({
          url: `/api/paper/${site_id}/${code}/recommend`,
          type: "get",
          params,
        }),
    },
  };

  api.cloud = {
    admin: {
      preview: (id, view_code) =>
        api.request({
          url: `/api/admin/draft_cloud/${id}/preview`,
          type: "get",
          params: {
            view_code,
          },
        }),
    },
    public: {
      index: (params) =>
        api.request({
          url: `/api/cloud/index`,
          type: "get",
          params,
        }),
      read: (site_id, code, secret_code) =>
        api.request({
          url: `/api/cloud/${site_id}/${code}`,
          type: "get",
          params: {
            ...(secret_code ? { secret_code } : null),
          },
        }),
      categoryIndex: (params) =>
        api.request({
          url: `/api/cloud/category/index`,
          type: "get",
          params,
        }),
      count: (params) =>
        api.request({
          url: `/api/cloud/count`,
          type: "get",
          params,
        }),
      recommend: (site_id, code, params) =>
        api.request({
          url: `/api/cloud/${site_id}/${code}/recommend`,
          type: "get",
          params,
        }),
    },
  };

  api.menu = {
    public: {
      read: (siteId) =>
        api.request({
          url: `/api/menu/${siteId}`,
          type: "get",
        }),
      preview: (id, code) =>
        api.request({
          url: `/api/admin/draft_menu/${id}/preview`,
          type: "get",
          params: { view_code: code },
        }),
    },
  };

  api.footer = {
    public: {
      read: (siteId) =>
        api.request({
          url: `/api/footer/${siteId}`,
          type: "get",
        }),
      preview: (id, code) =>
        api.request({
          url: `/api/admin/draft_footer/${id}/preview`,
          type: "get",
          params: { view_code: code },
        }),
    },
  };

  api.home = {
    admin: {
      preview: (id, view_code) =>
        api.request({
          url: `/api/admin/draft_homepage/${id}/preview`,
          type: "get",
          params: {
            view_code,
          },
        }),
    },
    public: {
      read: (site_id) =>
        api.request({
          url: `/api/homepage/${site_id}/home`,
          type: "get",
        }),
    },
  };

  api.site = {
    public: {
      index: (params = null) => api.request({
        url: `/api/site/index`,
        type: 'get',
        params,
      }),
    },
  }

  api.form = {
    admin: {
      preview: (id, view_code) =>
        api.request({
          url: `/api/admin/draft_form/${id}/preview`,
          type: "get",
          params: {
            view_code,
          },
        }),
    }
  }
};
