<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div
    @click="downloadConfirm"
    class="typeC-btn d-inline-flex align-center pointer"
  >
    <img :src="require('@/assets/img/icon/fileIcon.svg')" alt="" class="mr-2" />
    <span class="typeC-btn__text secondary--text text-overflow-ep-1 tmw-16-15 word-break--break-word">
      <slot>{{ filename }}</slot>
    </span>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import fileKernal from "@/components/fileList/fileKernal.js";
export default {
  mixins: [fileKernal],
  methods: {
    downloadConfirm() {
      this.download();
    },
  },
};
</script>