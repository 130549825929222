<template>
    <div 
        class="ecvNavbar__submenu__item__menu"
        :class="isInView ? '' : 'isOutView'"
    >
        <div v-for="(col, index) in getThirdMenuCol(items)" :key="index" class="ecvNavbar__submenu__item__menu_wrap">
            <link-node 
                v-for="thirdItem in col" 
                :key="thirdItem.id" 
                tooltip 
                :node="thirdItem"
                class="ecvNavbar__submenu__item__menu__item"
            ></link-node>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => []
        },
        index: {
            type: Number
        },
        isInView: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({
        perColumn: 5,
    }),
    methods: {
        getThirdMenuCol(thirdItems) {
            return thirdItems.reduce((acc, cur, index) => {
                if(index % this.perColumn == 0) {
                    acc.push([cur])
                } else {
                    acc[acc.length -1].push(cur)
                }
                return acc
            }, [])
        },
    },
}
</script>