<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <nav id="ecvNavbar" class="ecvNavbar" ref="ecvNavbar">
    <div id="ecvNavbar_nav">
      <sub-menu v-if="subMenuItems.length > 0" :posX="submenuPosLeft" :items="subMenuItems"></sub-menu>
      <section
        id="ecvNavbarWrapper"
        class="d-flex justify-space-between ecvNavbarWrapper"
        :class="ecvNavbarWrapperClass"
      >
        <div class="d-flex">
          <!-- ---Logo--- -->
          <div
            v-if="showLogo"
            @click="goHome"
            :class="logoBoxClass"
            class="ecvLogoBox d-flex justify-center align-center pointer"
          >
            <img
              class="ecv-logo-WHT"
              :src="require('@/assets/img/logo/ECV_logo_WHT.svg')"
              alt=""
            />
            <img
              class="ecv-logo-CLR"
              :src="require('@/assets/img/logo/ECV_logo_CLR.svg')"
              alt=""
            />
          </div>
          
          <template v-if="showHeaderMenu">
            <!-- ---選單列表--- -->
            <NavList class="ecvNavbar__nav-list" :items="items" />
            <!-- mobile 選單列表 -->
            <transition name="nav-mobile-menu">
              <MobileMenu v-show="mobileMenu" :items="items" :subMenuItems="subMenuItems"/>
            </transition>
          </template>
        </div>

        <!-- ---Search & Location--- -->
        <div class="ecvNavbar__utils d-flex align-center">
          <button
            v-if="showSearchBtn"
            @click="toggleEcvNavSearch"
            class="ecvNavSearch d-flex justify-center align-center"
          >
            <v-icon size="20" :color="iconColor">$search</v-icon>
          </button>
          <button
            v-show="showLocation"
            @click="toggleLocation"
            class="ecvNavLocation d-flex justify-center align-center"
          >
            <v-icon size="20" :color="iconColor"> $global </v-icon>
            <v-fade-transition>
              <!-- 語言選單 -->
              <div
                class="ecvLocationBox"
                :class="{ 'ecvLocationBox--active': ecvNavLocation }"
                :style="ecvLocationBoxStyle"
              >
                <div
                  class="ecvLocationMenu"
                  :class="{ 'ecvLocationMenu--active': menuActive }"
                  :style="ecvLocationMenuStyle"
                >
                  <div
                    v-for="item in siteList"
                    :key="item.id"
                    class="ecvLocationItem"
                  >
                    <a :href="getHref(item)">{{ item.label }}</a>
                  </div>
                </div>
              </div>
            </v-fade-transition>
          </button>
          <button
            @click="toggleMobileMenu"
            class="ecvNavBurger justify-center align-center"
          >
            <v-icon size="20">$burger</v-icon>
          </button>
        </div>
      </section>
    </div>


    <v-fade-transition>
      <div v-if="ecvNavSearch" @click.stop="" class="ecvSearchBox">
        <v-container class="inner-container">
          <SearchBox :dark="false" />
        </v-container>
      </div>
    </v-fade-transition>

    <!-- 麵包屑 -->
    <transition name="ecvNavbar__breadcrumb-fade">
      <Breadcrumb v-if="showBreadcrumb" />
    </transition>
  </nav>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import NavList from "./navList.vue";
import Breadcrumb from "./breadcrumb.vue";
import MobileMenu from "@/components/nav/mobileMenu.vue";
import SubMenu from './subMenu.vue';
export default {
  components: {
    NavList,
    Breadcrumb,
    MobileMenu,
    SubMenu,
  },
  async mounted() {
    await this.init();

    // 設定subMenu位置
    await this.setSubMenuPosition()
    await this.setNavHeight()
    
  },
  data: () => ({
    items: [],
    subMenuItems: [],
    siteList: [],
    ecvMenuWidth: 0,
    menuActive: false,
    ecvLocationMenuLine: 0,
    submenuPosLeft: 'auto',
  }),
  computed: {
    scroll() {
      return this.$store.getters["base/scroll"];
    },
    breadcrumbStatus() {
      return this.$store.getters["base/componentStatus"].breadcrumb;
    },
    showBreadcrumb() {
      return this.breadcrumbStatus != 0;
      return this.breadcrumbStatus != 0 && this.scroll <= 10;
    },
    navWhoOpen() {
      return this.$store.getters["base/navWhoOpen"];
    },
    headerMenuStatus() {
      return this.$store.getters["base/componentStatus"].header_menu;
    },
    logoStatus() {
      return this.$store.getters["base/componentStatus"].logo;
    },
    searchStatus() {
      return this.$store.getters["base/componentStatus"].search;
    },
    locationStatus() {
      return this.$store.getters["base/componentStatus"].location;
    },
    showLogo() {
      return this.logoStatus != 0;
    },
    showHeaderMenu() {
      return this.headerMenuStatus != 0;
    },
    showSearchBtn() {
      if (this.searchStatus == 0) return false;
      return this.$route.name != "search";
    },
    showLocation() {
      return this.locationStatus != 0;
    },
    menuId() {
      if (this.$route.name != "menu-preview") return null;
      return this.$route.params.id;
    },
    code() {
      if (this.$route.name != "menu-preview") return null;
      return this.$route.query.viewCode;
    },
    isPreviewMode() {
      if (!this.menuId) return false;
      if (!this.code) return false;
      return true;
    },
    siteId() {
      return this.$store.getters["base/siteId"];
    },
    ecvNavbarWrapperClass() {
      if (!this.showBreadcrumb) return "ecvNavbarWrapper--shadow";
      return "";
    },
    mobileMenu() {
      return this.$store.getters["base/mobileMenu"];
    },
    ecvNavLocation() {
      return this.$store.getters["base/ecvNavLocation"];
    },
    ecvNavSearch() {
      return this.$store.getters["base/ecvNavSearch"];
    },
    iconColor() {
      const { width } = this.$vuetify.breakpoint;
      if (width >= 900) return "white";
      return "secondary";
    },
    logoBoxClass() {
      const { width } = this.$vuetify.breakpoint;
      if (width >= 900) {
        if (this.logoChangeBase == 0) {
          if (this.scroll == 0) return "ecvLogoBox--white";
          return "ecvLogoBox--color";
        }
        if (this.scroll <= this.logoChangeBase - 50) return "ecvLogoBox--white";
      }
      return "ecvLogoBox--color";
    },
    logoChangeBase() {
      return this.$store.getters[`base/logoChangeBase`];
    },
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
    windowHeight() {
      return this.$vuetify.breakpoint.height;
    },
    mainApiLoadDone() {
      return this.$store.getters["base/mainApiLoadDone"];
    },
    navHeight() {
      return this.$store.getters["base/navHeight"];
    },
    ecvLocationBoxStyle() {
      const allHeight = $(window)[0].innerHeight;
      const option = {
        "max-height": `calc(${allHeight}px - ${this.navHeight}px)`,
      };
      if (this.windowWidth >= this.$vuetify.breakpoint.thresholds.lg) {
        option.width = `${this.ecvMenuWidth}px`;
      }
      // 加上 overflow-y: auto 是為了讓menu超出螢幕時可以上下滑動, 但加了之後會在breakpoint.lg以上無法正確折行
      if(!this.ecvLocationMenuMaxHeight) {
        option['overflow-y'] = 'auto'
      }
      return option;
    },
    ecvLocationMenuStyle() {
      const option = {};
      if (!this.ecvLocationMenuMaxHeight) return null

      if (this.ecvLocationMenuLine)
        option.width = `${200 * this.ecvLocationMenuLine}px`;
      if (this.ecvLocationMenuMaxHeight)
        option["max-height"] = `${this.ecvLocationMenuMaxHeight}px`;
      return option;
    },
    ecvLocationMenuMaxHeight() {
      if (this.windowWidth < this.$vuetify.breakpoint.thresholds.lg)
        return null;
      const total = this.siteList.length;
      if (total == 0) return null;

      const temp = Math.ceil(total / 10);
      const quotient = Math.floor(total / temp);
      const remain = total % temp;
      const result = 43.19 * (quotient + remain) + 2; // +2 是因為某些browser會自動把item的高四捨五入進位導致計算有誤
      return result;
    },
  },
  watch: {
    async windowWidth() {
      await this.setNavBreadcrumbHeight();
      await this.setEcvLocationMenuLine();
      await this.getMenuWidth();
      await this.setSubMenuPosition()
    },
    async mainApiLoadDone() {
      await this.setNavBreadcrumbHeight();
    },
    navWhoOpen: {
      deep: true,
      handler() {
        if (!this.navWhoOpen) {
          this.removeWindowClick();
          return;
        }
        // 手機板的menu有自己處理方式
        if (this.navWhoOpen == "mobileMenu") return;
        this.onWindowClick();
      },
    },
    $route: {
      deep: true,
      handler() {
        this.$store.commit("base/setNav", {
          key: "desktopMenu",
          status: false,
        });
        this.$store.commit("base/setDesktopMenuOpenIndex", -1);
      },
    },
  },
  created() {
    this.$root.$on("setNavBreadcrumbHeight", this.setNavBreadcrumbHeight);
  },
  beforeDestroy() {
    this.$root.$off("setNavBreadcrumbHeight");
  },
  methods: {
    async setEcvLocationMenuLine() {
      await this.$nextTick();
      if (!this.ecvLocationMenuMaxHeight) return 0;
      const total = this.siteList.length;
      this.ecvLocationMenuLine = Math.ceil(
        (43.19 * total) / this.ecvLocationMenuMaxHeight
      );
    },
    onWindowClick() {
      $(window).on("click", this.handleWindowClick);
    },
    removeWindowClick() {
      $(window).off("click", this.handleWindowClick);
    },
    handleWindowClick(e) {
      const nav = $("#ecvNavbar")[0];
      const contain = nav.contains(e.target);
      if (!contain) this.$store.commit("base/closeNav");
    },
    async setNavBreadcrumbHeight() {
      await this.setNavHeight();
      await this.setBreadcrumbHeight();
    },
    async setNavHeight() {
      await this.$nextTick();
      const nav = $("#ecvNavbar_nav")[0];
      const subNav = $('#ecvNavbar__submenu')[0]

      if(nav) {
        const height = nav.offsetHeight;
        this.$store.dispatch("base/setNavHeight", height);
      }

      if(subNav) {
        const height = subNav.offsetHeight;
        this.$store.dispatch("base/setSubNavHeight", height);
      }

    },
    async setBreadcrumbHeight() {
      await this.$store.dispatch(`base/setBreadcrumbHeight`);
    },
    async callMenu() {
      if (this.isPreviewMode) {
        return await this.$api.menu.public.preview(this.menuId, this.code);
      }
      return await this.$api.menu.public.read(this.siteId);
    },
    async getSiteList() {
      return await this.$api.site.public.index({ pager: 0 });
    },
    async init() {
      try {
        this.siteList = await this.getSiteList();
        const result = await this.callMenu();
        this.items = _get(result, "data.children", []);
        this.subMenuItems = _get(result, "sub_data.children", []);
        
      } catch (error) {
        console.error(error);
      }
    },
    toggleMobileMenu() {
      if (this.headerMenuStatus == 2) return;
      this.$store.commit("base/setNav", {
        key: "mobileMenu",
        status: !this.mobileMenu,
      });
    },
    async toggleLocation() {
      if (this.locationStatus == 2) return;
      this.$store.commit("base/setNav", {
        key: "ecvNavLocation",
        status: !this.ecvNavLocation,
      });
      await this.$nextTick();
      this.menuActive = true;
      await this.$nextTick();
      await this.$nextTick();
      await this.setEcvLocationMenuLine();
      await this.getMenuWidth();
    },
    async getMenuWidth() {
      await this.$nextTick();
      const width = $(this.$el).find(".ecvLocationMenu").width();
      this.ecvMenuWidth = width;
    },
    toggleEcvNavSearch() {
      if (this.searchStatus == 2) return;
      this.$store.commit("base/setNav", {
        key: "ecvNavSearch",
        status: !this.ecvNavSearch,
      });
    },
    getHref(item) {
      const resolve = this.$router.resolve({
        params: { siteCode: item.code },
        name: "home",
      });
      return resolve.route.fullPath;
    },
    goHome() {
      if (this.logoStatus == 2) return;
      const { siteCode } = this.$route.params;
      this.$router.push({ name: "home", params: { siteCode } });
    },
    async setSubMenuPosition() {
      await this.$nextTick()
      const {left} = document.querySelector('.ecvNavbar__utils').getBoundingClientRect()
      this.submenuPosLeft = `${left}px`
    }
  },
};
</script>
